body {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

html,
body {
    &::-webkit-scrollbar {
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    scrollbar-width: auto;
    scrollbar-color: #888 #f1f1f1;
    -ms-overflow-style: scroll;
}

@import "./styles/main.scss";