// Normalization
body {
    color: $ford-twilight;
}

h1,
h2,
h3 {
    color: $ford-twilight;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

// App styles
.k-button {
    color: $ford-twilight;
}

.ford-blue-btn {
    font-family: FordF1;
    font-weight: 500;
    font-size: 14px;
    color: $white;
    background-color: $ford-skyview;
    border-style: none;
    border-radius: 400px;
    box-sizing: content-box;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
    height: 16px;
    padding: 12px 16px 12px 16px;
    text-decoration: none;
    width: 150px;
    margin: 10px;

    &:hover {
        background-color: $button-hover;
    }
}

.k-dialog-buttongroup {
    margin-top: 20px;
}

.k-animation-container {
    z-index: 10003;
}

.k-drawer-container {
    background-color: $light-gray;

    .k-drawer {
        box-shadow: 5px 0 10px -5px $ford-twilight;
        border-right-width: 0 !important;
        background-color: $ford-blue;
    }

    &.k-drawer-push .k-drawer {
        position: sticky;
        top: 80px;
        height: calc(100vh - 80px);
    }

    .k-drawer-item {
        user-select: none;
        color: $light-gray;
        font-weight: 300;
        background-color: $ford-blue !important;
        font-size: 16px;

        &:hover {
            color: $white !important;
            background-color: $ford-blue;
            font-weight: 500;
        }

        &.k-state-selected {
            background-color: $ford-skyview !important;
            font-weight: 500;
        }

        .k-icon {
            height: 14px;
            margin-top: 4px;
            width: 18px;
        }
    }
}

.k-drawer-content {
    height: 100%;
}

.k-grid,
.k-label,
.k-toolbar,
.k-column-title,
.k-grid-toolbar,
.k-form {
    color: $ford-twilight
}

.card-container {
    background-color: $white;
    color: $ford-twilight;
    padding: 40px 0;
    text-align: center;

    &.grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 10px;
        row-gap: 1em;
    }

    .card-buttons {
        .k-button {
            min-width: 105px;
        }
    }

    .card-ranges {
        .k-daterangepicker {

            .k-textbox-container,
            .k-floating-label-container {
                padding-top: 0;
                min-width: 105px;
            }

            .k-label {
                display: none;
            }
        }
    }

    .card-component {
        color: $ford-twilight;
        grid-column: 1 / -1;
        overflow: hidden;
    }
}

.k-toolbar::before {
    content: none;
    display: none;
}

.k-toolbar {
    justify-content: center;
}

@media (min-width: 480px) {

    .card-container {
        text-align: initial;
        padding: 30px;

        &.grid {
            grid-template-columns: repeat(2, 1fr);
        }

        .card-title {
            grid-column: 1 / 2;
            grid-row: 1;
        }

        .card-buttons {
            text-align: center;
            grid-column: 1 / -1;
            grid-row: 2;
        }

        .card-ranges {
            text-align: right;
            grid-column: 2 / 3;
            grid-row: 1;
        }
    }

}

@media (max-width: 768px) {
    .card-title {
        padding-top: 10px
    }
}



@media (min-width: 768px) {
    .card-container {
        margin: 25px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);

        &.grid {
            grid-template-columns: repeat(3, 1fr);
        }

        .card-buttons {
            text-align: center;
            grid-column: 2 / 3;
            grid-row: 1;
        }

        .card-ranges {
            text-align: right;
            grid-column: 3 / 4;
        }

        .card-component {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
}

// Header
.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 3;
    background-image: linear-gradient(to right, $ford-blue , $ford-skyview);

    .menu-button {
        margin-left: 25px;
    }

    .k-icon {
        color: $light-gray;
        font-size: 20px;
        cursor: pointer;
    }
}

// Waitlist Page
.waitlist-page {
    .k-grid td {
        border-width: 0 0 2px 1px;
        vertical-align: middle;
    }
}

// Home page 
.home-page {
    background-color: $white;
    color: $ford-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        font-size: 30px;
        font-weight: 500;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    img {
        max-width: 350px;
    }

    a {
        cursor: pointer;
        color: $ford-skyview;
        font-size: 14px;
        text-decoration: underline;
    }

    .btn-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ford-blue-btn {
        font-size: 14px;
        width: 150px;
        margin: 10px;
    }
}

// Login Page
.login-page {
    background-color: $white;
    color: $ford-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        font-size: 30px;
        font-weight: 500;
    }

    img {
        max-width: 325px;
    }

    a {
        cursor: pointer;
        color: $ford-skyview;
        font-size: 14px;
        text-decoration: underline;
    }

    .btn-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .k-form {
        width: 350px;

    }

    .k-label {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 13px;
        color: $ford-blue;
        font-weight: 500;
    }

    .k-textbox {
        border-radius: 5px;
    }
}


// Dashboard page
.dashboard-page {
    .card-container:last-of-type {
        padding-top: 0;
    }
    .k-grid td {
        border-width: 0 0 2px 1px;
        vertical-align: middle;
    }

    .k-grid-toolbar .k-textbox {
        margin-bottom: 10px;
    }

    .k-grid .k-virtual-content,
    .k-grid .k-virtual-content > div {
    -ms-overflow-style: scroll; 
    scrollbar-width: auto; 

    &::-webkit-scrollbar {
        width: 12px; 
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
}

    .k-badge {
        z-index: 1
    }
}

@media (min-width: 480px) {
    .dashboard-page {
        .k-grid-toolbar .k-textbox {
            margin-bottom: 0;
            float: left;
        }

        .k-grid-toolbar .k-button {
            float: right;
        }
    }
}

@media (min-width: 768px) {
    .dashboard-page {
        .card-container:last-of-type {
            padding-top: 30px;
        }
    }
}

// Planning page 
.planning-page {
    .card-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .card-title {
        grid-column: 1 / -1;
    }

    .k-hbox {
        padding: 0 20px;
        background: transparent;
        align-items: center;
    }

    .k-card-title {
        font-size: 15px;
        margin-bottom: -6px;
        text-align: left;
    }

    .k-card-subtitle {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 11px;
    }

    .disabled {
        opacity: 0.5;
    }
}

@media (min-width: 480px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: 768px) {
    .planning-page {
        .k-hbox {
            padding: 0;
            border-width: 0;
        }
    }
}

@media (min-width: 960px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (min-width: 1360px) {
    .planning-page {
        .card-container {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

// Profile page
.profile-page {
    .card-container {
        text-align: left;
    }

    .k-form .k-form-field {
        display: block;
        padding: 0 15px;
    }

    .k-form-field:first-of-type .k-form-field-wrap {
        margin: auto;
    }

    .k-form-field:first-of-type .k-avatar {
        margin: 0 auto 1rem;
    }

    .k-form.k-form-horizontal .k-label {
        width: 100%;
    }

    hr {
        margin-top: 1rem;
        opacity: 0.2;
    }

    .k-form .k-form-buttons {
        justify-content: center;

        &>* {
            min-width: 140px;
        }
    }
}

@media (min-width: 480px) {
    .profile-page {
        .k-form .k-form-field {
            display: flex;
        }

        .k-form-field:first-of-type .k-avatar {
            margin: 0;
        }

        .k-form.k-form-horizontal .k-label {
            width: 25%;
        }
    }
}

@media (min-width: 768px) {
    .profile-page {
        .k-form {
            margin: 0 auto;
            padding: 0;
        }

        .k-form .k-form-field {
            padding: 0;
        }
    }
}


// Info page
.info-page {
    background-color: $info-background;
    color: $white;

    h1,
    h2,
    h3,
    .component-link {
        font-family: FordF1, Helvetica, Arial, sans-serif;
    }

    a:not(.k-button) {
        color: $link-color;
    }

    .content {
        padding-top: 40px;
        margin: 0 auto;
        position: relative;
        max-width: 820px;
    }

    .kendoka {
        text-align: center;

        img {
            max-width: 280px;
        }
    }

    .section-1 {
        text-align: center;

        h1 {
            font-size: 48px;
            font-weight: bold;
            color: $react-color;
        }

        h2 {
            font-size: 30px;
            margin-bottom: 53px;
        }

        .button-group {
            margin-bottom: 30px;

            .k-button {
                display: block;
                margin: 10px auto;
                min-width: 145px;
                width: 60%;
            }
        }

        .github-link {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            align-content: center;

            .github-text {
                margin-left: 15px;
            }
        }
    }

    .section-2 {
        text-align: center;
        margin-top: 40px;
        padding: 0 15px;
    }

    .section-3 {
        -webkit-columns: 2;
        column-count: 2;
        margin-top: 40px;
        padding: 0 15px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }

    .package-item {
        display: inline-block;
        margin-top: 20px;
    }

    .package-title a {
        font-size: 17px;
        color: #CCCCCC;
        text-transform: uppercase;
    }

    .component-link {
        padding: 5px 0;

        a {
            font-size: 20px;
        }
    }

    .footer {
        background-image: url('../assets/info-background.svg');
        background-repeat: no-repeat;
        height: 320px;
    }
}

@media (min-width: 480px) {
    .info-page {
        .content {
            padding: 40px 40px 0;
        }

        .kendoka {
            img {
                max-width: 348px;
            }
        }

        .section-1 {
            h1 {
                font-size: 71px;
            }

            h2 {
                font-size: 38px;
            }

            .button-group {
                .k-button {
                    display: inline-block;
                    margin-right: 10px;
                    width: auto;
                }
            }
        }

        .section-3 {
            -webkit-columns: 3;
            column-count: 3;
        }
    }
}

@media (max-width: 480px) {
    .k-form-horizontal .k-form-field-wrap {
        max-width: 100%;
    }

}

@media (min-width: 768px) {
    .info-page {
        .content {
            padding: 120px 40px 0;
        }

        .kendoka {
            position: absolute;
            right: 0;
            top: 2em;
            text-align: left;
        }

        .section-1,
        .section-2 {
            text-align: left;
            padding: 0;
        }

        .section-3 {
            -webkit-columns: 4;
            column-count: 4;
            padding: 0;
        }

        .footer {
            background-size: cover;
        }
    }
}

.approve-btn {
    background-color: $lemon-team;
}


.approve_donation_dialog {
    width: 50%;
    margin-left: 25%;

    .dealer_address_cntr {
        font-weight: 700;
        width: 50%;
        margin-left: 25%;
        text-align: center;
    }

    .address_unverified_text {
        width: 80%;
        margin-left: 10%;
        text-align: left;
    }

}

// Resend Emails form
.send-emails-subtext {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: grey;
    font-style: italic;
}

#email_type_label {
    width: auto;
}