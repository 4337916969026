@font-face {
    font-family: FordF1;
    font-style: normal;
    font-weight: 100 300;
    src: url('//cdn.latitudewebservices.com/fonts/f1/light.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/light.woff') format("woff")
}

@font-face {
    font-family: FordF1;
    font-style: normal;
    font-weight: 400;
    src: url('//cdn.latitudewebservices.com/fonts/f1/regular.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/regular.woff') format("woff")
}

@font-face {
    font-family: FordF1;
    font-style: normal;
    font-weight: 500;
    src: url('//cdn.latitudewebservices.com/fonts/f1/medium.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/medium.woff') format("woff")
}

@font-face {
    font-family: FordF1;
    font-style: normal;
    font-weight: 600;
    src: url('//cdn.latitudewebservices.com/fonts/f1/semibold.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/semibold.woff') format("woff")
}

@font-face {
    font-family: FordF1;
    font-style: normal;
    font-weight: 700 900;
    src: url('//cdn.latitudewebservices.com/fonts/f1/bold.woff2') format("woff2"), url('//cdn.latitudewebservices.com/fonts/f1/bold.woff') format("woff")
}


body {
    font-family: FordF1, sans-serif;
}

h1 {
    font-size: 1.25rem;
    font-weight: 400;
}

h2 {
    font-size: 1.0rem;
    ;
    font-weight: 200;
}

h3 {
    font-size: 1.25rem;
    font-weight: 400;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.25rem;
}

p {
    font-size: 1rem;
}

a {
    font-size: 1rem;
}

@media (min-width: 480px) {
    h1 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    h2 {
        font-size: 1.25rem;
        ;
        font-weight: 200;
    }
}

@media (min-width: 768px) {
    h1 {
        font-size: 1.75rem;
        font-weight: 400;
    }

    h2 {
        font-size: 1.75rem;
        ;
        font-weight: 200;
    }
}